// JavaScript Document
$(function(){
	// sp/pc共通
	$('.btn_hamburger').click(function () {
		$('.btn_search').removeClass('active');
		$('.header_searchbox').css('display', 'none');
		$('.btn_hamburger').toggleClass('active');
		$('.sd_menu_nav').slideToggle(250);
		return false;
	});
	$('.sd_menu_nav--icon01').click(function () {
		$('.header_searchbox').css('display', 'none');
		$('.btn_hamburger').toggleClass('active');
		$('.sd_menu_nav').slideToggle(250);
	});
	$('.sd_menu_nav--icon02').click(function () {
		$('.header_searchbox').css('display', 'none');
		$('.btn_hamburger').toggleClass('active');
		$('.sd_menu_nav').slideToggle(250);
	});
	$('.sd_menu_nav--icon03').click(function () {
		$('.header_searchbox').css('display', 'none');
		$('.btn_hamburger').toggleClass('active');
		$('.sd_menu_nav').slideToggle(250);
	});
	$('.btn_search').click(function () {
		$('.btn_hamburger').removeClass('active');
		$('.sd_menu_nav').css('display', 'none');
		$('.btn_search').toggleClass('active');
		$('.header_searchbox').slideToggle(250);
		return false;
	});

	if( window.matchMedia("screen and (max-width:768px)").matches ) {
		//スマデバ
		//アコーディオン（資料リスト）
		var contentsCount = $(".document").length;
		var n = 6;

		if(contentsCount <= n) {
			$(".more_btn").hide();
		} else {
			$(".document").slice(n).hide();

			$(".more_btn").click(function(){
				if ($(".document").slice(n).is(':hidden')) {
					$(".document").slice(n).slideDown();
					$(this).text('ー 閉じる');
				} else {
					$(".document").slice(n).slideUp();
					$(this).text('＋ もっと見る');
				}
			});
		}
	}
});